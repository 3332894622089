import { useBreakpointValue } from '@chakra-ui/media-query';

type ScreenInfosType = {
  isMobile: boolean;
  isDesktop: boolean;
};

export const useScreenInfos = (): ScreenInfosType => {
  const isMobile =
    useBreakpointValue(
      {
        base: true,
        md: false,
      },
      { ssr: false }
    ) ?? false;

  return {
    isMobile,
    isDesktop: !isMobile,
  };
};
